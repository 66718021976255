import data from '../Data/data.json';
import { useParams } from 'react-router-dom';

export default function Recipe() {

    const { recipeId } = useParams();

    const recipe = data.recipes[recipeId - 1]

    return (
        <div className="grid place-items-center">
            <h2 className="text-3xl mx-4">{recipe.title}</h2>
            {recipe.description.length > 0 && <p className="text-lg mt-2 mx-6 max-w-2xl text-left">{recipe.description}</p>}
            <p className="text-2xl mx-4 mt-6 mb-2">Ingredienser</p>
            <ul className="text-lg mx-6 max-w-lg text-justify">
                {recipe.ingredients.map(ingredient => {
                    return (<li><p>{ingredient}</p></li>);
                })}
            </ul>
            <p className="text-2xl mx-4 mt-8 mb-2">Fremgangsmåde</p>
            <ol className="text-lg mx-6 max-w-2xl text-justify">
                {recipe.steps.map((step, index) => {
                    return (<li className="mb-2"><p>{index + 1}: {step}</p></li>);
                })}
            </ol>
        </div>
    );
}