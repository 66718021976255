import { Header } from '../Components';
import { Outlet } from "react-router-dom";

export default function Root() {

  return (
    <div data-theme="retro" className="text-center">
      <Header />
      <Outlet />
    </div>
  );
}

