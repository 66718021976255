import data from '../Data/data.json';
import { Link } from "react-router-dom";

export default function RecipeList() {

    return (
        <ul>
          {data && data.recipes.map(recipe => {
              return (
                <li key={recipe.id} className="m-4">
                  <Link to={`recipe/${recipe.id}`} className="link link-hover text-2xl">{recipe.title}</Link>
                </li>
              );
            })
          }
        </ul>
      );
}
