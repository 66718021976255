import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from './routes/Root';
import Recipe from './routes/Recipe';
import RecipeList from './routes/RecipeList';
import ErrorPage from './error-page';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <RecipeList />
        },
        {
          path: "recipe/:recipeId",
          element: <Recipe />
        }
      ]
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
